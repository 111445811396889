import React, { useEffect, useReducer, useState } from 'react'
import { validForm } from '../../Hooks/validForm'
import { useQuery } from '@tanstack/react-query'
import utilities, { GetResponseWnds, createGetApi, dataStr_ToArray, getCntWtInNumbers, svLnxSrvr } from '../../utilities/utilities'
import { Button } from "@mui/material";
import { nrjAxios } from '../../Hooks/useNrjAxios';
import WtrRsSelect from '../../components/reusable/nw/WtrRsSelect';
import NrjRsDt from '../../components/reusable/NrjRsDt';
import NrjAgGrid from '../../components/reusable/NrjAgGrid';
import { getFldValue } from '../../Hooks/useGetFldValue';
import { Toaster } from '../../components/reusable/Toaster';
import { useNavigate } from 'react-router-dom';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useEffectOnce } from 'react-use';
import { storeFormData, storeGidData, storePrintData, storeTableData } from '../store/feature/DailySummary/dailySummarySlice';
import { isStateLogin, showFieldInColdef } from '../../utilities/Hsp';
import DisplayHcfDataCard from '../../components/reusable/DisplayHcfDataCard';
import {Loader} from '../../components/Loader';


const ACTIONS = {
    TRIGGER_GRID: "grdtrigger",
    NEWROWDATA: "newrow",
    RANDOM: "rndm",
    TRIGGER_FORM: "trgfrm",
    FORM_DATA: "frmdata",
    SETFORM_DATA: "setfrmdata",
    MAINID: "mnid",
    CHECK_REQ: "chckreq",
    CHECK_REQDONE: "chckreqdn",
    SETGID: "gd",
    DISABLE: "disable"
};

const initialState = {
    triggerG: 0,
    nwRow: [],
    rndm: 0,
    trigger: 0,
    textDts: "",
    mainId: 0,
    errMsg: [],
    openDrwr: false,
    frmData: "",
    gid: "",
    disableA: 1,
    disableB: 1,
    disableC: 1,
};

type purBill = {
    triggerG: number;
    nwRow: any;
    rndm: number;
    trigger: number;
    textDts: string;
    mainId: number;
    errMsg: any;
    openDrwr: boolean;
    frmData: string;
    gid: string;
    disableA: number,
    disableB: number,
    disableC: number,
};

type act = {
    type: string;
    payload: any;
};

const reducer = (state: purBill, action: act) => {
    let newstate: any = { ...state };
    switch (action.type) {
        case ACTIONS.MAINID:
            newstate.mainId = action.payload;
            newstate.gid = ""
            newstate.rndm += 1;
            return newstate;
        case ACTIONS.TRIGGER_GRID:
            newstate.triggerG = action.payload;
            return newstate;
        case ACTIONS.TRIGGER_FORM:
            newstate.trigger = action.payload;
            if (action.payload === 0) {
                newstate.textDts = "";
                newstate.frmData = "";
                newstate.mainId = 0;
            }
            return newstate;
        case ACTIONS.NEWROWDATA:
            newstate.nwRow = action.payload;
            newstate.triggerG += 10;
            return newstate;
        case ACTIONS.RANDOM:
            newstate.rndm += 1;
            return newstate;
        case ACTIONS.FORM_DATA:
            let dtaF: string = "";
            let fldNF: any = utilities(2, action.payload, "");
            if (newstate.textDts) {
                dtaF = newstate.textDts + "=";
                let d: any = utilities(1, dtaF, fldNF);
                if (d) {
                    dtaF = d;
                } else {
                    dtaF = "";
                }
            }
            dtaF += action.payload;
            newstate.textDts = dtaF;
            return newstate;
        case ACTIONS.SETFORM_DATA:
            newstate.frmData = action.payload;
            return newstate;
        case ACTIONS.CHECK_REQ:
            newstate.errMsg = action.payload;
            newstate.openDrwr = true;
            return newstate;
        case ACTIONS.CHECK_REQDONE:
            newstate.errMsg = "";
            newstate.openDrwr = false;
            return newstate;
        case ACTIONS.SETGID:
            newstate.gid = action.payload;
            return newstate;
        case ACTIONS.DISABLE:
            if (newstate.disableA == 1) {
                newstate.disableA = 0
            }
            else {
                newstate.disableA = 1
            }
            return newstate;
    }
};



const DailySummaryScnBy = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const reqFlds = [{ fld: 'dt', msg: 'Enter Date', chck: '' }];
    const [showMessage, setShowMessage] = useState<any>({ message: [] })
    const [isLoading, setIsLoading] = useState("");
    const [cbwtfName, setCbwtfName] = useState("");
    const [gd, setGd] = useState<string>("")

    const coldef = [
        { field: 'id', hide: true, width: 0, headerName: '' },
        { field: 'cbwtf', hide: false, width: 150, headerName: 'SPCB Code' },
        { field: 'cntr', hide: false, width: 290, headerName: 'HCF' },
        { field: 'rtu', hide: false, width: 180, headerName: 'Route' },
        { field: 'bdcnt', hide: true, width: 120, headerName: 'No Of Beds' },
        // { field: 'bdcnt', hide: !showFieldInColdef('shwbd'), width:130, headerName: 'No Of Beds'},
        // { field: 'rsn', hide: false, width: 120, headerName: 'Region' },
        // { field: 'wstid', hide: false, width: 200, headerName: 'Waste Bags ID' },
        // { field: 'lblno', hide: false, width: 140, headerName: 'Label Number' },
        {
            headerName: 'Red',
            children: [
                { field: 'rcnt', hide: false, width: 75, headerName: 'Bags', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
                { field: 'rwt', hide: false, width: 90, headerName: 'Kg/gms', cellStyle: { color: 'black', 'background-color': '#ffcccb' } },
            ],
        },
        {
            headerName: 'Yellow',
            children: [
                { field: 'ycnt', hide: false, width: 75, headerName: 'Bags', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                { field: 'ywt', hide: false, width: 90, headerName: 'Kg/gms', cellStyle: { color: 'black', 'background-color': '#FDFD97' } }
            ],
        },
        {
            headerName: 'White',
            children: [
                { field: 'wcnt', hide: false, width: 75, headerName: 'Bags' },
                { field: 'wwt', hide: false, width: 90, headerName: 'Kg/gms' }
            ],
        },
        {
            headerName: 'Blue',
            children: [
                { field: 'bcnt', hide: false, width: 75, headerName: 'Bags', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } },
                { field: 'bwt', hide: false, width: 90, headerName: 'Kg/gms', cellStyle: { color: 'black', 'background-color': '#ADD8E6' } }
            ],
        },
        {
            headerName: 'Cytotoxic',
            children: [
                { field: 'ccnt', hide: false, width: 75, headerName: 'Bags', cellStyle: { color: 'black', 'background-color': '#FDFD97' } },
                { field: 'cwt', hide: false, width: 90, headerName: 'Kg/gms', cellStyle: { color: 'black', 'background-color': '#FDFD97' } }
            ],
        },
        {
            headerName: 'Total',
            children: [
                { field: 'tcnt', hide: false, width: 75, headerName: 'Bags' },
                { field: 'twt', hide: false, width: 90, headerName: 'Kg/gms' }
            ],
        },

        { field: 'bsnt', hide: true, width: 150, headerName: 'Bags sent to CPCB Server' },
        { field: 'bdst', hide: true, width: 200, headerName: 'Bags scanned from correct distance' },
    ];
    const [countTillNow, setCountTillNow] = useState<number>(250);
    const GridLoaded = () => { };
    const onRowSelected = (data: string) => { };
    const onButtonClicked = (action: string, rm: any) => { };
    const navigate = useNavigate();
    let emptyCard: any = { redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 };
    const [scnbyFct, setScnbyFct] = useState({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
    const [scnbyHcf, setScnbyHcf] = useState({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
    const [scnbySup, setScnbySup] = useState({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })


    const onChangeDts = (data: string) => {
        setGd("")
        dispatch({ type: ACTIONS.SETGID, payload: "" });

        dispatch({ type: ACTIONS.FORM_DATA, payload: data });
        setScnbyFct({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
        setScnbyHcf({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
        setScnbySup({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
    };


    const GetGid = () => {
        let g: any = utilities(3, "", "");
        dispatch({ type: ACTIONS.SETGID, payload: g });
        return g;
    };


    const getClick = () => {
        let api: string = state.textDts;
        let msg: any = validForm(api, reqFlds);
        setShowMessage({ message: msg, type: 'error' });
        if (msg && msg[0]) {
            dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
            setTimeout(function () {
                dispatch({ type: ACTIONS.CHECK_REQDONE, payload: 1 });
            }, 2500);
            return;
        }
        setIsLoading("Loading data please wait....");
        if (state.disableA == 1) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        }
        setScnbyFct({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
        setScnbyHcf({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
        setScnbySup({ redcnt: 0, ylwcnt: 0, blucnt: 0, whtcnt: 0, cytcnt: 0, redwt: 0, ylwwt: 0, bluwt: 0, whtwt: 0, cytwt: 0, tcnt: 0, twt: 0 })
        refetch();
        // refetchB();
        // refetchD();
    }

    const GetData = () => {
        let gid = GetGid();
        setGd(gid)
        let dt = state.textDts;
        dt = getFldValue(dt, "dt")

        let api: string = createGetApi("db=nodb|dll=cntbkdll|fnct=g97", `${dt}=0=${gid}`);

        return nrjAxios({ apiCall: api });
    };

    const populateGrid = (data: any) => {
        if (state.disableA == 0) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        }
        let dt: string = GetResponseWnds(data);
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });

        if (dt) {
            setIsLoading("");
            let ary: any = dataStr_ToArray(dt);
            let total = { ...emptyCard }
            if (ary){
                ary = ary.map((res: any) => {
                    let data = getCntWtInNumbers(res);
                    total['redcnt'] += data.redcnt;
                    total['redwt'] += data.redwt;
                    total['blucnt'] += data.blucnt;
                    total['bluwt'] += data.bluwt;
                    total['ylwcnt'] += data.ylwcnt;
                    total['ylwwt'] += data.ylwwt;
                    total['whtcnt'] += data.whtcnt;
                    total['whtwt'] += data.whtwt;
                    total['cytcnt'] += data.cytcnt;
                    total['cytwt'] += data.cytwt;
                    total['tcnt'] += data.redcnt + data.blucnt + data.ylwcnt + data.whtcnt + data.cytcnt;
                    total['twt'] += data.redwt + data.bluwt + data.ylwwt + data.whtwt + data.cytwt;
                    return ary;
                })
    
            } else {
                setShowMessage({ message: ["Try After Some time"], type: 'error' });
            }
            

            setScnbyHcf(total);

        } else {
            setIsLoading("")
            setShowMessage({ message: ["Data is not there at the moment, Inconvenience is regreted."], type: 'error' })
        }

        setTimeout(function () {
            refetchB();
        }, 500)

    }

    const populateGrid2 = (dataB: any) => {
        if (state.disableA == 0) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        }
        let dt: string = GetResponseWnds(dataB);
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });

        if (dt) {
            setIsLoading("");
            let ary: any = dataStr_ToArray(dt);
            let total = { ...emptyCard }
            if (ary) {
                ary = ary.map((res: any) => {
                    let data = getCntWtInNumbers(res);
                    total['redcnt'] += data.redcnt;
                    total['redwt'] += data.redwt;
                    total['blucnt'] += data.blucnt;
                    total['bluwt'] += data.bluwt;
                    total['ylwcnt'] += data.ylwcnt;
                    total['ylwwt'] += data.ylwwt;
                    total['whtcnt'] += data.whtcnt;
                    total['whtwt'] += data.whtwt;
                    total['cytcnt'] += data.cytcnt;
                    total['cytwt'] += data.cytwt;
                    total['tcnt'] += data.redcnt + data.blucnt + data.ylwcnt + data.whtcnt + data.cytcnt;
                    total['twt'] += data.redwt + data.bluwt + data.ylwwt + data.whtwt + data.cytwt;
                    return ary;
                })
    
            } else {
                setIsLoading("")
                setShowMessage({ message: ["Data is not there at the moment, Inconvenience is regreted."], type: 'error' })    
            }
            
            setScnbySup(total);
        } else {
            setIsLoading("")
            setShowMessage({ message: ["Data is not there at the moment, Inconvenience is regreted."], type: 'error' })
        }


        setTimeout(function () {
            refetchD();
        }, 500)
    }

    const populateGrid3 = (dataD: any) => {
        if (state.disableA == 0) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        }
        let dt: string = GetResponseWnds(dataD);
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });
        if (dt) {
            setIsLoading("");
            let ary: any = dataStr_ToArray(dt);
            let total = { ...emptyCard }
            if (ary) {
                ary = ary.map((res: any) => {
                    let data = getCntWtInNumbers(res);
                    total['redcnt'] += data.redcnt;
                    total['redwt'] += data.redwt;
                    total['blucnt'] += data.blucnt;
                    total['bluwt'] += data.bluwt;
                    total['ylwcnt'] += data.ylwcnt;
                    total['ylwwt'] += data.ylwwt;
                    total['whtcnt'] += data.whtcnt;
                    total['whtwt'] += data.whtwt;
                    total['cytcnt'] += data.cytcnt;
                    total['cytwt'] += data.cytwt;
                    total['tcnt'] += data.redcnt + data.blucnt + data.ylwcnt + data.whtcnt + data.cytcnt;
                    total['twt'] += data.redwt + data.bluwt + data.ylwwt + data.whtwt + data.cytwt;
                    return ary;
                })
                setScnbyFct(total);
            } else {
                setIsLoading("")
                setShowMessage({ message: ["Data is not there at the moment, Inconvenience is regreted."], type: 'error' })    
            }

            
        } else {
            setIsLoading("")
            setShowMessage({ message: ["Data is not there at the moment, Inconvenience is regreted."], type: 'error' })
        }

    }


    const { data, refetch } = useQuery({
        queryKey: ['svQry', 'smryScnby1', state.textDts, state.rndm],
        queryFn: GetData,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: populateGrid,
    })

    const GetDataSec = () => {
        let gid = gd;
        let dt = state.textDts;
        dt = getFldValue(dt, "dt")
        let api: string = createGetApi("db=nodb|dll=cntbkdll|fnct=g97", `${dt}=1=${gid}`);
        return nrjAxios({ apiCall: api });
    };

    const { data: dataB, refetch: refetchB } = useQuery({
        queryKey: ['svQry', 'summaryScnby2_1', state.textDts],
        queryFn: GetDataSec,
        enabled: false,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: populateGrid2,
    })

    const GetDataThird = () => {
        let gid = gd;
        let dt = state.textDts;
        dt = getFldValue(dt, "dt")
        let api: string = createGetApi("db=nodb|dll=cntbkdll|fnct=g97", `${dt}=2=${gid}`);
        return nrjAxios({ apiCall: api });
    };

    const { data: dataD, refetch: refetchD } = useQuery({
        queryKey: ['svQry', 'summaryScnby3_1', state.textDts],
        queryFn: GetDataThird,
        enabled: false,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: populateGrid3,
    })

    const PrntRep = () => {
        let gid: string = gd
        if (!gid) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 });
            setShowMessage({ message: ["populate the data in the grid first"], type: 'error' });
            return;
        }
        let dt: string = state.textDts;
        dt = getFldValue(dt, "dt")
        let api: string = createGetApi("db=nodb|dll=chqdll|fnct=g127", `33=${gid}=${dt}`);
        return nrjAxios({ apiCall: api });
    };

    const ShowReprtt = (dataC: any) => {
        let dt: string = GetResponseWnds(dataC);
        if (state.disableA == 0) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 });
        }
        if (dt && dt.indexOf('.pdf') > -1) {
            window.open(dt, "_blank")
        }
        else {
            setShowMessage({ message: ["Please try again after refreshing the page!"], type: 'error' })
        }
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });
    }

    const { data: dataC, refetch: refetchC } = useQuery({
        queryKey: ['prntRep', 'dailySmryScnby', state.rndm],
        queryFn: PrntRep,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: ShowReprtt,
    })


    const printClick = () => {
        if (state.disableA == 1) {
            dispatch({ type: ACTIONS.DISABLE, payload: 0 });
        }
        refetchC()
    }
    const getCbwtf = () => {
        let cbwtfid: string = sessionStorage.getItem("statebrdcbwtf") || "1";
        let api: string = createGetApi("db=nodb|dll=cntbkdll|fnct=g85", `${cbwtfid}`);
        return nrjAxios({ apiCall: api });
    }

    const getCbwtfSUccess = (data: any) => {
        if (state.disableA == 0) {
            dispatch({ type: ACTIONS.DISABLE, payload: 1 })
        }
        dispatch({ type: ACTIONS.RANDOM, payload: 1 });
        let dt: string = GetResponseWnds(data);
        if (dt) {
            setCbwtfName(getFldValue(dt, 'cbwtf'));
            dispatch({ type: ACTIONS.SETFORM_DATA, payload: dt })
        }
    }


    const { data: dataSvd2, refetch: refetchCbwtf } = useQuery({
        queryKey: ["getCbwtfIdHcfnotvstd", state.mainId, state.rndm],
        queryFn: getCbwtf,
        enabled: false,
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: getCbwtfSUccess,
    });

    useEffect(() => {
        if (isStateLogin()) {
            refetchCbwtf();
        }
    }, [])


    return (
        <>
            <div className='text-lg text-center mb-2 relative top[-5px] font-semibold'>
                {cbwtfName}
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3">
                <div className="mb-2">
                    <NrjRsDt
                        onChange={onChangeDts}
                        Label="Date"
                        idText="txtdt"
                        selectedValue={state.frmData}
                        fldName='dt'
                        IAmRequired={reqFlds}
                        speaker={'Enter Date'}
                    ></NrjRsDt>
                </div>
                <div className="flex" style={{ marginTop: '34px' }}>
                    {/* <div className="lg:flex-grow">
                    </div> */}
                    <div className='mx-2'>
                        <Button
                            size="medium"
                            style={{ color: "#34c3ff", backgroundColor: "#fff" }}
                            variant="contained"
                            color="success"
                            startIcon={<GetAppIcon />}
                            disabled={!state.disableA}
                            onClick={getClick}>
                            Get
                        </Button>
                    </div>
                    <div className='mx-2'>
                        <Button
                            size="medium"
                            style={{ color: "#34c3ff", backgroundColor: "#fff" }}
                            variant="contained"
                            color="success"
                            startIcon={<LocalPrintshopIcon />}
                            disabled={!state.disableA}
                            onClick={printClick}>
                            Print
                        </Button>
                    </div>

                </div>
            </div>
            {/* <div className="absolute font-semibold text-lg">{isLoading}</div>
            {showMessage && showMessage.message.length != 0 ? <div className="relative py-2">
                <Toaster data={showMessage} className={''}></Toaster>
            </div> : <></>} */}

            <div className="relative">
                {/* Loader Display */}
                {isLoading  && (typeof isLoading === 'string'  && String(isLoading).includes("Loading"))  && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                        <Loader />
                    </div>
                )}

                {/* Toast Message */}
                {!isLoading && showMessage && showMessage.message.length !== 0 && (
                    <div className="relative py-2">
                        <Toaster data={showMessage} className="" />
                    </div>
                )}
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-4 mt-12">
                <div className='mx-3'>
                    <DisplayHcfDataCard name='Health Care Facility' data={scnbyHcf}></DisplayHcfDataCard>
                </div>
                <div className='mx-3'>
                    <DisplayHcfDataCard name='Operator' data={scnbySup}></DisplayHcfDataCard>
                </div>
                <div className='mx-3'>
                    <DisplayHcfDataCard name='Plant' data={scnbyFct}></DisplayHcfDataCard>
                </div>
            </div>

        </>
    );
};
export default React.memo(DailySummaryScnBy);

